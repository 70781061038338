import PropTypes from 'prop-types';
import React from 'react';
import Textarea from '@salesforce/design-system-react/components/textarea';

import OptInDetails from './OptInDetails';

function OIDExistingRelationship(props) {
  const { onChange } = props;

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'existing-relationship',
          field: (
            <Textarea
              label="Description of Existing Relationship"
              maxLength="4000"
              onChange={(event) => onChange({ existingRelationship: event.target.value })}
              required
            />
          ),
        },
        {
          key: 'permission-details',
          field: (
            <Textarea
              label="When and How Was Permission Received?"
              maxLength="4000"
              onChange={(event) => onChange({ permissionDetails: event.target.value })}
              required
            />
          ),
        },
      ]}
    />
  );
}

OIDExistingRelationship.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDExistingRelationship;
