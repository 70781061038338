import PropTypes from 'prop-types';
import React from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import Textarea from '@salesforce/design-system-react/components/textarea';

import OptInDetails from './OptInDetails';

function OIDTradeshowConvention(props) {
  const { onChange } = props;

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'tradeshow-date',
          field: (
            <Datepicker
              labels={{ label: 'Tradeshow/Convention Date' }}
              onChange={(_, data) => onChange({ tradeshowDate: data.formattedDate })}
              required
            />
          ),
        },
        {
          key: 'tradeshow-description',
          field: (
            <Textarea
              label="Tradeshow/Convention Description"
              maxLength="4000"
              onChange={(event) => onChange({ tradeshowDescription: event.target.value })}
              required
            />
          ),
        },
      ]}
    />
  );
}

OIDTradeshowConvention.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDTradeshowConvention;
