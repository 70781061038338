import PropTypes from 'prop-types';
import React from 'react';

function ReviewInformationItem(props) {
  const { item, label } = props;

  if (!item) return null;

  return (
    <>
      {typeof item === 'string' && (
        <>
          <div className="slds-form-element__label">{label}</div>
          <div className="review-item-text">{item}</div>
        </>
      )}

      {item instanceof File && (
        <>
          <div className="slds-form-element__label">{label}</div>
          <img
            alt={item.name}
            className="slds-m-top_xx-small"
            src={URL.createObjectURL(item)}
            style={{ display: 'block' }}
          />
        </>
      )}
    </>
  );
}

ReviewInformationItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  label: PropTypes.string.isRequired,
};

ReviewInformationItem.defaultProps = {
  item: undefined,
};

export default ReviewInformationItem;
