import React from 'react';

function NotFound() {
  return (
    <div className="slds-text-align_center slds-m-around_medium">
      This page cannot be found. Please check your URL and try again.
    </div>
  );
}

export default NotFound;
