import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import Input from '@salesforce/design-system-react/components/input';

import TimezonePicker from './TimezonePicker';

function DateTimePicker(props) {
  const [date, setDate] = useState();
  const [time, setTime] = useState('');
  const [timezone, setTimezone] = useState();

  const {
    dateLabel, onChange, propertyName, timeLabel,
  } = props;

  useEffect(() => {
    const timeComponent = time.length > 0 ? ` ${time} ${timezone}` : '';
    const dateTime = `${date}${timeComponent}`;
    onChange({ [propertyName]: dateTime });
  }, [date, time, timezone]);

  return (
    <>
      <Datepicker
        dateDisabled={(data) => data.date >= new Date()}
        labels={{ label: dateLabel }}
        onChange={(_, data) => setDate(data.formattedDate)}
        required
      />

      <div
        className="inline-input slds-m-left_small"
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <Input
          label={timeLabel}
          onChange={(_, { value }) => setTime(value)}
          styleInput={{ maxHeight: '32px', width: '200px' }}
          type="time"
        />
      </div>

      <div className="slds-m-top_xx-small">
        <TimezonePicker
          onChange={(value) => setTimezone(value)}
        />
      </div>
    </>
  );
}

DateTimePicker.propTypes = {
  dateLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
};

export default DateTimePicker;
