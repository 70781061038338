import PropTypes from 'prop-types';
import React from 'react';
import Input from '@salesforce/design-system-react/components/input';

import DateTimePicker from '../DateTimePicker/DateTimePicker';
import OptInDetails from './OptInDetails';

function OIDWebsite(props) {
  const { onChange } = props;

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'opt-in-date',
          field: (
            <DateTimePicker
              dateLabel="Opt-In Date"
              onChange={onChange}
              propertyName="optInDate"
              timeLabel="Opt-In Time"
            />
          ),
        },
        {
          key: 'opt-in-form-url',
          field: (
            <Input
              label="Opt-In Form URL"
              maxLength="500"
              onChange={(_, { value }) => onChange({ optInURL: value })}
              type="url"
            />
          ),
        },
      ]}
    />
  );
}

OIDWebsite.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDWebsite;
