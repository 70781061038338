import PropTypes from 'prop-types';
import React from 'react';
import Textarea from '@salesforce/design-system-react/components/textarea';

import OptInDetails from './OptInDetails';

function OIDOther(props) {
  const { onChange } = props;

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'permission-details',
          field: (
            <Textarea
              label="When and How Was Permission Received?"
              maxLength="4000"
              onChange={(event) => onChange({ permissionDetails: event.target.value })}
              required
            />
          ),
        },
      ]}
    />
  );
}

OIDOther.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDOther;
