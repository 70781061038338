import React from 'react';

function Success() {
  return (
    <div className="slds-text-align_center slds-m-top_large">
      <div className="slds-m-vertical_x-small">Thank you for submitting the requested proof of opt-in.</div>
      <div className="slds-m-vertical_x-small">We will reach out via email with further updates on the status of the complaint investigation.</div>
    </div>
  );
}

export default Success;
