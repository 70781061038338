import React, { createRef, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Input from '@salesforce/design-system-react/components/input';
import ProgressIndicator from '@salesforce/design-system-react/components/progress-indicator';
import Textarea from '@salesforce/design-system-react/components/textarea';

import OIDAffiliatePartner from './OptInDetails/OIDAffiliatePartner';
import OIDExistingRelationship from './OptInDetails/OIDExistingRelationship';
import OIDOther from './OptInDetails/OIDOther';
import OIDProductPurchase from './OptInDetails/OIDProductPurchase';
import OIDTradeshowConvention from './OptInDetails/OIDTradeshowConvention';
import OIDWebsite from './OptInDetails/OIDWebsite';
import OptInStatement from './OptInStatement';
import OptInType from './OptInType';
import ReviewInformation from './ReviewInformation/ReviewInformation';

const OPT_IN_WEBSITE = 'WEBSITE';
const OPT_IN_PRODUCT_PURCHASE = 'PRODUCT_PURCHASE';
const OPT_IN_TRADESHOW_CONVENTION = 'TRADESHOW_CONVENTION';
const OPT_IN_EXISTING_RELATIONSHIP = 'EXISTING_RELATIONSHIP';
const OPT_IN_AFFILIATE_PARTNER = 'AFFILIATE_PARTNER';
const OPT_IN_OTHER = 'OTHER';

const steps = [
  { id: 0, label: 'Select Opt-In Method' },
  { id: 1, label: 'Provide Opt-In Details' },
  { id: 2, label: 'Submit Information' },
];

function IntakeForm() {
  const [accountID, setAccountID] = useState('');
  const [additionalComments, setAdditionalComments] = useState();
  const [caseNumber, setCaseNumber] = useState();
  const [collectedInformation, setCollectedInformation] = useState();
  const [completedSteps, setCompletedSteps] = useState([]);
  const [optInDetails, setOptInDetails] = useState({});
  const [optInMethod, setOptInMethod] = useState();
  const [optInStatementDetails, setOptInStatementDetails] = useState({});
  const [optInTypeDetails, setOptInTypeDetails] = useState({});
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState('');
  const [recipient, setRecipient] = useState('');
  const [searchParams] = useSearchParams();
  const [selectedStep, setSelectedStep] = useState(steps[0]);

  const formContentRef = createRef();
  const navigate = useNavigate();

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    if (!params.accountID || !params.caseNumber || !params.recipient) {
      navigate('/error');
    }

    setAccountID(params.accountID);
    setCaseNumber(params.caseNumber);
    setRecipient(params.recipient);
  }, []);

  const moveToStep = (stepID) => {
    setCompletedSteps(steps.slice(0, stepID));
    setSelectedStep(steps[stepID]);

    if (stepID === 2) formContentRef.current.setAttribute('hidden', '');
    else formContentRef.current.removeAttribute('hidden');
  };

  const optInMethodSelected = (_, { selection }) => {
    if (!selection.length) return;
    setOptInMethod(selection);
    setOptInDetails({});
    moveToStep(1);
  };

  const removeEmptyProperties = (object) => Object
    .fromEntries(Object
      .entries(object)
      .filter(([, value]) => {
        if (value === undefined) return false;
        if (typeof value !== 'string') return true;
        return value.length > 0;
      }));

  const submitForm = (event) => {
    event.preventDefault();

    setCollectedInformation(
      removeEmptyProperties({
        caseNumber,
        accountID,
        recipient,
        optInMethod: optInMethod[0].label,
        ...optInDetails,
        ...optInTypeDetails,
        ...optInStatementDetails,
        privacyPolicyURL,
        additionalComments,
      }),
    );

    moveToStep(2);
  };

  const updateOptInDetails = (updatedValue) => {
    setOptInDetails({ ...optInDetails, ...updatedValue });
  };

  return (
    <>
      <Input
        className="slds-m-top_medium"
        disabled
        id="account-id"
        label="Account ID"
        value={accountID}
      />

      <Input
        className="slds-m-top_xx-small"
        disabled
        id="recipient"
        label="Recipient"
        value={recipient}
      />

      <div className="form-container">
        <div style={{ flex: 'none' }}>
          <ProgressIndicator
            completedSteps={completedSteps}
            orientation="vertical"
            selectedStep={selectedStep}
            steps={steps}
          />
        </div>

        <div ref={formContentRef} style={{ flexGrow: 1 }}>
          <Combobox
            events={{ onSelect: optInMethodSelected }}
            labels={{ label: 'Method of Opt-In' }}
            options={[
              { id: OPT_IN_WEBSITE, label: 'Website' },
              { id: OPT_IN_PRODUCT_PURCHASE, label: 'Product Purchase' },
              { id: OPT_IN_TRADESHOW_CONVENTION, label: 'Tradeshow/Convention' },
              { id: OPT_IN_EXISTING_RELATIONSHIP, label: 'Existing Relationship' },
              { id: OPT_IN_AFFILIATE_PARTNER, label: 'Affiliate/Partner' },
              { id: OPT_IN_OTHER, label: 'Other' },
            ]}
            selection={optInMethod}
            variant="readonly"
          />

          {selectedStep.id >= 1 && (
            <form onSubmit={submitForm}>
              <hr className="slds-m-top_medium slds-m-bottom_x-small" />

              {optInMethod[0].id === OPT_IN_AFFILIATE_PARTNER
                && <OIDAffiliatePartner onChange={updateOptInDetails} />}
              {optInMethod[0].id === OPT_IN_EXISTING_RELATIONSHIP
                && <OIDExistingRelationship onChange={updateOptInDetails} />}
              {optInMethod[0].id === OPT_IN_OTHER
                && <OIDOther onChange={updateOptInDetails} />}
              {optInMethod[0].id === OPT_IN_PRODUCT_PURCHASE
                && <OIDProductPurchase onChange={updateOptInDetails} />}
              {optInMethod[0].id === OPT_IN_TRADESHOW_CONVENTION
                && <OIDTradeshowConvention onChange={updateOptInDetails} />}
              {optInMethod[0].id === OPT_IN_WEBSITE
                && <OIDWebsite onChange={updateOptInDetails} />}

              <hr className="slds-m-top_medium slds-m-bottom_x-small" />

              <OptInType
                onChange={(updatedValue) => setOptInTypeDetails(
                  { ...optInTypeDetails, ...updatedValue },
                )}
              />

              <OptInStatement
                onChange={(updatedValue) => setOptInStatementDetails(
                  { ...optInStatementDetails, ...updatedValue },
                )}
              />

              <hr className="slds-m-top_medium slds-m-bottom_x-small" />

              <div className="slds-m-vertical_xx-small">
                <Input
                  label="Privacy Policy URL"
                  maxLength="500"
                  onChange={(_, { value }) => setPrivacyPolicyURL(value)}
                  type="url"
                  value={privacyPolicyURL}
                />
              </div>

              <div className="slds-m-vertical_xx-small">
                <Textarea
                  label="Additional Comments"
                  maxLength="4000"
                  onChange={(event) => setAdditionalComments(event.target.value)}
                  value={additionalComments}
                />
              </div>

              <div className="slds-m-top_small slds-text-align_center">
                <Button label="Validate" type="submit" variant="brand" />
              </div>
            </form>
          )}
        </div>

        {selectedStep.id === 2 && (
          <ReviewInformation
            information={collectedInformation}
            onBack={() => moveToStep(1)}
          />
        )}
      </div>
    </>
  );
}

export default IntakeForm;
