import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Textarea from '@salesforce/design-system-react/components/textarea';

import DateTimePicker from '../DateTimePicker/DateTimePicker';
import OptInDetails from './OptInDetails';

const purchaseTypes = [
  { id: '0', label: 'In Person' },
  { id: '1', label: 'Online' },
];

function OIDProductPurchase(props) {
  const [purchaseType, setPurchaseType] = useState([purchaseTypes[0]]);

  const { onChange } = props;

  useEffect(
    () => onChange({ purchaseType: purchaseType[0].label }),
    [purchaseType],
  );

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'transaction-date',
          field: (
            <DateTimePicker
              dateLabel="Transaction Date"
              onChange={onChange}
              propertyName="transactionDate"
              timeLabel="Transaction Time"
            />
          ),
        },
        {
          key: 'purchase-type',
          field: (
            <Combobox
              events={{
                onSelect: (_, { selection }) => {
                  if (!selection.length) return;
                  setPurchaseType(selection);
                },
              }}
              labels={{ label: 'Type of Purchase' }}
              options={purchaseTypes}
              required
              selection={purchaseType}
              variant="readonly"
            />
          ),
        },
        {
          key: 'purchase-details',
          field: (
            <Textarea
              label="Description of Product/Service Purchased"
              maxLength="4000"
              onChange={(event) => onChange({ purchaseDetails: event.target.value })}
              required
            />
          ),
        },
      ]}
    />
  );
}

OIDProductPurchase.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDProductPurchase;
