import PropTypes from 'prop-types';
import React, { createRef, useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';

const FILE_SIZE_LIMIT = 2 * 1024 * 1024;
const FILE_TOO_LARGE = 'File uploaded is too large - Maximum file size allowed is 2 MB';
const NOT_AN_IMAGE = 'File uploaded was not an image.';

function ImageUpload(props) {
  const [error, setError] = useState('');
  const [filename, setFilename] = useState('');
  const inputRef = createRef();

  const { onImageUpload } = props;

  const checkFile = (event) => {
    setError('');
    setFilename('');

    const file = event.target.files[0];
    inputRef.current.value = '';

    if (file.type.split('/')[0] !== 'image') {
      setError(NOT_AN_IMAGE);
      onImageUpload(undefined);
      return;
    }

    if (file.size > FILE_SIZE_LIMIT) {
      setError(FILE_TOO_LARGE);
      onImageUpload(undefined);
      return;
    }

    setFilename(file.name);
    onImageUpload(file);
  };

  const clearFile = () => {
    setError('');
    setFilename('');
    onImageUpload(undefined);
  };

  return (
    <>
      <input
        hidden
        onChange={checkFile}
        ref={inputRef}
        type="file"
      />

      <Button
        iconCategory="utility"
        iconName="upload"
        iconPosition="left"
        label="Upload image"
        onClick={() => inputRef.current.click()}
      />

      {filename.length > 0 && (
        <div className="slds-m-left_small" style={{ display: 'inline-block' }}>
          <Icon
            category="doctype"
            containerClassName="slds-m-right_xx-small"
            containerStyle={{ verticalAlign: 'text-bottom' }}
            name="image"
            size="x-small"
          />
          {filename}
        </div>
      )}

      {error.length > 0 && (
        <div
          className="slds-m-left_small slds-text-color_error"
          style={{ display: 'inline-block' }}
        >
          {error}
        </div>
      )}

      {(filename.length > 0 || error.length > 0) && (
        <div className="slds-m-left_small" style={{ display: 'inline-block' }}>
          <Button
            iconCategory="utility"
            iconName="clear"
            onClick={clearFile}
            style={{ verticalAlign: 'text-bottom' }}
            variant="icon"
          />
        </div>
      )}
    </>
  );
}

ImageUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
};

export default ImageUpload;
