import PropTypes from 'prop-types';
import React from 'react';

function OptInDetails(props) {
  const { inputFields } = props;

  return (
    <>
      {inputFields.map((inputField) => (
        <div className="slds-m-vertical_xx-small" key={inputField.key}>
          {inputField.field}
        </div>
      ))}
    </>
  );
}

OptInDetails.propTypes = {
  inputFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    field: PropTypes.element.isRequired,
  })).isRequired,
};

export default OptInDetails;
