import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import timezones from 'timezones.json';

const formatOffset = (offset) => {
  const prefix = offset < 0 ? '' : '+';
  const offsetString = offset.toString();
  const [hour, decimal] = offsetString.split('.');
  const minutes = decimal ? parseFloat(`.${decimal}`) * 60 : 0;
  return `${prefix}${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const timezoneOptions = timezones.map((timezone) => ({
  id: timezone.text,
  label: timezone.text,
  offset: formatOffset(timezone.offset),
  utc: timezone.utc,
}));

function TimezonePicker(props) {
  const [selectedTimezone, setSelectedTimezone] = useState();

  const { onChange } = props;

  useEffect(() => {
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultOption = timezoneOptions
      .find((timezone) => timezone.utc.includes(defaultTimezone));
    setSelectedTimezone([defaultOption]);
    onChange(defaultOption.offset);
  }, []);

  return (
    <Combobox
      events={{
        onSelect: (_, { selection }) => {
          if (!selection.length) return;
          setSelectedTimezone(selection);
          onChange(selection[0].offset);
        },
      }}
      labels={{ label: 'Timezone' }}
      options={timezoneOptions}
      selection={selectedTimezone}
      variant="readonly"
    />
  );
}

TimezonePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TimezonePicker;
