import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Textarea from '@salesforce/design-system-react/components/textarea';

import ImageUpload from './ImageUpload';

const STATEMENT_EXISTS_YES = 'STATEMENT_EXISTS_YES';
const STATEMENT_EXISTS_NO = 'STATEMENT_EXISTS_NO';

const statementExistsOptions = [
  { id: STATEMENT_EXISTS_YES, label: 'Yes' },
  { id: STATEMENT_EXISTS_NO, label: 'No' },
];

function OptInStatement(props) {
  const [optInStatement, setOptInStatement] = useState();
  const [optInStatementExists, setStatementExists] = useState([statementExistsOptions[0]]);
  const [optInStatementRef, setOptInStatementRef] = useState();

  const { onChange } = props;

  return (
    <>
      <div className="slds-m-vertical_xx-small">
        <Combobox
          events={{
            onSelect: (_, { selection }) => {
              if (!selection.length) return;
              setStatementExists(selection);
            },
          }}
          labels={{ label: 'Does Explicit Statement of Consent/Opt-In Exist?' }}
          options={statementExistsOptions}
          required
          selection={optInStatementExists}
          variant="readonly"
        />
      </div>

      {optInStatementExists[0].id === STATEMENT_EXISTS_YES && (
        <>
          <div className="slds-m-vertical_xx-small">
            <Textarea
              label="Exact Statement of Consent/Opt-In Wording"
              maxLength="4000"
              onChange={({ target: { value } }) => {
                setOptInStatement(value);
                onChange({ optInStatement: value });
              }}
              placeholder="This can alternatively be provided as a screenshot by uploading an image below."
              required
              textareaRef={setOptInStatementRef}
              value={optInStatement}
            />
          </div>

          <div className="slds-m-vertical_xx-small">
            <ImageUpload
              onImageUpload={(image) => {
                onChange({ optInStatementImage: image });

                if (image) {
                  optInStatementRef.removeAttribute('required');
                } else {
                  optInStatementRef.setAttribute('required', '');
                }
              }}
            />
          </div>
        </>
      )}
    </>
  );
}

OptInStatement.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OptInStatement;
