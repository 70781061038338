import React from 'react';

import logo from './logo.png';

function Header() {
  return (
    <>
      <img src={logo} alt="Logo" className="logo" />

      <div className="slds-text-heading_medium slds-text-align_center slds-m-top_medium">
        Salesforce Marketing Cloud
        <br />
        Compliance Attestation
      </div>
    </>
  );
}

export default Header;
