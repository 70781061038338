import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';

import DateTimePicker from './DateTimePicker/DateTimePicker';

const OPT_IN_TYPE_SINGLE = 'OPT_IN_SINGLE';
const OPT_IN_TYPE_DOUBLE = 'OPT_IN_DOUBLE';
const OPT_IN_TYPE_OPT_OUT = 'OPT_IN_OPT_OUT';

const optInTypes = [
  { id: OPT_IN_TYPE_SINGLE, label: 'Single Opt-In' },
  { id: OPT_IN_TYPE_DOUBLE, label: 'Double Opt-In' },
  { id: OPT_IN_TYPE_OPT_OUT, label: 'Opt-Out' },
];

const DATE_PROPERTY_NAME = 'doubleOptInDate';

function OptInType(props) {
  const [optInType, setOptInType] = useState([optInTypes[0]]);

  const { onChange } = props;

  useEffect(() => onChange({ optInType: optInType[0].label }), []);

  return (
    <>
      <div className="slds-m-vertical_xx-small">
        <Combobox
          events={{
            onSelect: (_, { selection }) => {
              if (!selection.length) return;
              setOptInType(selection);

              const changes = { optInType: selection[0].label };

              if (selection[0].id !== OPT_IN_TYPE_DOUBLE) {
                changes[DATE_PROPERTY_NAME] = undefined;
              }

              onChange(changes);
            },
          }}
          labels={{ label: 'Type of Opt-In' }}
          options={optInTypes}
          required
          selection={optInType}
          variant="readonly"
        />
      </div>

      {optInType[0].id === OPT_IN_TYPE_DOUBLE && (
        <div className="slds-m-vertical_xx-small">
          <DateTimePicker
            dateLabel="Double Opt-In Confirmation Date"
            onChange={onChange}
            propertyName={DATE_PROPERTY_NAME}
            timeLabel="Double Opt-In Confirmation Time"
          />
        </div>
      )}
    </>
  );
}

OptInType.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OptInType;
