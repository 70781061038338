import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';

import ReviewInformationItem from './ReviewInformationItem';

function ReviewInformation(props) {
  const [confirmed, setConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmisisionError] = useState('');

  const { information, onBack } = props;
  const navigate = useNavigate();

  const readAsDataURLAsync = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!confirmed) {
      setShowError(true);
      return;
    }

    setSubmitting(true);

    const body = { ...information };
    if (body.optInStatementImage) {
      body.optInStatementImage = await readAsDataURLAsync(information.optInStatementImage);
    }

    const response = await fetch('/v1/submit-case', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      setSubmisisionError(await response.text());
      setSubmitting(false);
      return;
    }

    navigate('/csa-success');
  };

  return (
    <form onSubmit={onSubmit} style={{ flexGrow: 1 }}>
      <div style={{ lineHeight: 1.75 }}>
        <ReviewInformationItem label="Opt-In Method" item={information.optInMethod} />
        <ReviewInformationItem label="Opt-In Date" item={information.optInDate} />
        <ReviewInformationItem label="Description of Affiliate Marketing/Co-Registration/Partner Opt-In" item={information.partnerDetails} />
        <ReviewInformationItem label="Opt-In Form URL" item={information.optInURL} />
        <ReviewInformationItem label="Transaction Date" item={information.transactionDate} />
        <ReviewInformationItem label="Purchase Type" item={information.purchaseType} />
        <ReviewInformationItem label="Description of Product/Service Purchased" item={information.purchaseDetails} />
        <ReviewInformationItem label="Tradeshow/Convention Date" item={information.tradeshowDate} />
        <ReviewInformationItem label="Tradeshow/Convention Description" item={information.tradeshowDescription} />
        <ReviewInformationItem label="Description of Existing Relationship" item={information.existingRelationship} />
        <ReviewInformationItem label="When and How Was Permission Received?" item={information.permissionDetails} />
        <ReviewInformationItem label="Type of Opt-In" item={information.optInType} />
        <ReviewInformationItem label="Double Opt-In Confirmation Date" item={information.doubleOptInDate} />
        <ReviewInformationItem label="Exact Statement of Consent/Opt-In Wording" item={information.optInStatement} />
        <ReviewInformationItem label="Exact Statement of Consent/Opt-In Upload" item={information.optInStatementImage} />
        <ReviewInformationItem label="Privacy Policy URL" item={information.privacyPolicyURL} />
        <ReviewInformationItem label="Additional Comments" item={information.additionalComments} />

        <Checkbox
          checked={confirmed}
          className="slds-m-top_small"
          labels={{ label: 'I confirm that all information provided above is correct to the best of my knowledge' }}
          onChange={(event) => {
            setConfirmed(event.target.checked);
            setShowError(false);
          }}
        />

        {showError && (
          <div className="slds-text-color_error slds-m-top_xx-small">You must confirm this information is correct before submitting</div>
        )}
      </div>

      <div className="slds-m-top_small slds-text-align_center">
        <Button disabled={submitting} label="Back" onClick={onBack} />
        <Button disabled={submitting} label="Submit" type="submit" variant="brand" />

        {submissionError.length > 0 && (
          <div className="slds-text-color_error slds-m-top_small">
            {`Submission failed: ${submissionError}`}
          </div>
        )}
      </div>
    </form>
  );
}

ReviewInformation.propTypes = {
  information: PropTypes.shape({
    accountID: PropTypes.string.isRequired,
    additionalComments: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    doubleOptInDate: PropTypes.string,
    existingRelationship: PropTypes.string,
    optInDate: PropTypes.string,
    optInMethod: PropTypes.string,
    optInStatement: PropTypes.string,
    optInStatementImage: PropTypes.instanceOf(File),
    optInType: PropTypes.string.isRequired,
    optInURL: PropTypes.string,
    partnerDetails: PropTypes.string,
    permissionDetails: PropTypes.string,
    privacyPolicyURL: PropTypes.string,
    purchaseDetails: PropTypes.string,
    purchaseType: PropTypes.string,
    recipient: PropTypes.string.isRequired,
    tradeshowDate: PropTypes.string,
    tradeshowDescription: PropTypes.string,
    transactionDate: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default ReviewInformation;
