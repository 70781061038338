import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

import Header from './Header';
import IntakeForm from './IntakeForm';
import NotFound from './NotFound';
import Success from './Success';
import reportWebVitals from './reportWebVitals';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <IconSettings iconPath="/assets/icons">
        <div className="container">
          <Header />

          <Routes>
            <Route path="/csa" element={<IntakeForm />} />
            <Route path="/csa-success" element={<Success />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </IconSettings>
    </React.StrictMode>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
