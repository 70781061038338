import PropTypes from 'prop-types';
import React from 'react';
import Input from '@salesforce/design-system-react/components/input';
import Textarea from '@salesforce/design-system-react/components/textarea';

import DateTimePicker from '../DateTimePicker/DateTimePicker';
import OptInDetails from './OptInDetails';

function OIDAffiliatePartner(props) {
  const { onChange } = props;

  return (
    <OptInDetails
      inputFields={[
        {
          key: 'opt-in-date',
          field: (
            <DateTimePicker
              dateLabel="Opt-In Date"
              onChange={onChange}
              propertyName="optInDate"
              timeLabel="Opt-In Time"
            />
          ),
        },
        {
          key: 'partner-details',
          field: (
            <Textarea
              label="Description of Affiliate Marketing/Co-Registration/Partner Opt-In"
              maxLength="4000"
              onChange={(event) => onChange({ partnerDetails: event.target.value })}
              required
            />
          ),
        },
        {
          key: 'opt-in-url',
          field: (
            <Input
              label="Opt-In Form URL"
              maxLength="500"
              onChange={(_, { value }) => onChange({ optInURL: value })}
              type="url"
            />
          ),
        },
      ]}
    />
  );
}

OIDAffiliatePartner.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OIDAffiliatePartner;
